<template>
  <div class="admin-page">
    <div v-if="!adminLogin" class="login-div">
      <div class="login">
        <div class="title">
          <strong style="font-size: 2vw; color: var(--my-color)"
            >半缘花意后台管理系统</strong
          >
        </div>
        <div class="login-tip" style="font-size: 1.5vw; margin-top: 1vh">
          请登录
        </div>
        <a-form class="login-form" :model="adminLoginForm">
          <a-form-item field="name" label="账号" show-colon required>
            <a-input
              v-model="adminLoginForm.name"
              placeholder="请输入管理员账号"
            />
          </a-form-item>
          <a-form-item field="password" label="密码" show-colon required>
            <a-input
              v-model="adminLoginForm.password"
              placeholder="请输入管理员密码"
            />
          </a-form-item>
        </a-form>
        <a-button style="width: 50%; margin-top: 2vh" @click="adminLoginBt"
          >登录</a-button
        >
      </div>
    </div>
    <div v-else class="admin-div">
      <div class="admin-head">
        <div class="head-title">半缘花意后台管理系统</div>
        <div class="admin-avatar"><a-avatar>admin</a-avatar></div>
      </div>
      <div class="admin-container">
        <div class="admin-menu">
          <a-menu
            :style="{ height: '92vh' }"
            :default-selected-keys="['0']"
          >
            <a-menu-item key="0">花品管理</a-menu-item>
          </a-menu>
        </div>
        <div class="admin-main">
          <floral-manager></floral-manager>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { Message } from "@arco-design/web-vue";
import axios from "axios";
import { onMounted, ref } from "vue";
import FloralManager from "@/components/admin/FloralManager.vue"

const adminLogin = ref(false);
const adminLoginForm = ref({
  name: "",
  password: "",
});
document.title = "半缘花意后台管理";
onMounted(() => [
  (adminLogin.value = window.localStorage.getItem("adminLogin")),
]);
const adminLoginBt = () => {
  axios
    .post("/user/admin-login", adminLoginForm.value)
    .then((res) => {
      if (res.data.code == 0) {
        adminLogin.value = true;
        Message.success("登录成功");
        window.localStorage.setItem("adminLogin", true);
      } else {
        Message.error(res.data.msg);
      }
    })
    .catch((err) => {});
};
</script>

<style scoped>
.login-div {
  width: 40vw;
  height: 40vh;
  margin: auto;
  margin-top: 25vh;
  background-color: #fff;
  border-radius: 20px;
}
.admin-div {
  width: 100vw;
  height: 100vh;
  margin: auto;
}
.admin-head {
  width: 100vw;
  height: 8vh;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.head-title {
  margin-left: 5vw;
  color: var(--my-color);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 2vw;
  font-weight: bold;
}
.admin-avatar {
  margin-right: 5vw;
}
.login {
  padding-top: 2%;
  width: 90%;
  margin: auto;
}
.login-form {
  margin-top: 5%;
}
.admin-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92vh;
}
.admin-menu{
  flex: 0.2;
}
.admin-main{
  flex:0.8;
  height: 100%;
}
</style>
<template>
  <a-carousel
    :style="{
      width: '100%',
      height: '100%',
      borderRadius: '15px',
    }"
    arrow-class="arrow-class"
    :default-current="1"
    @change="handleChange"
    indicator-type="never"
    auto-play
  >
    <a-carousel-item style="border-radius: 15px">
      <div class="swiper-bg1">
        <div class="swiper-item1">
          <img
            src="../assets/artFont.png"
            alt="logo"
            style="
              width: 40%;
              aspect-ratio: 376/89;
              margin-top: 4%;
              margin-bottom: 2%;
            "
          />
          <div
            class="artistic-text"
            style="letter-spacing: 7px; user-select: none; margin-bottom: 4%"
          >
            您的线上花店<span>&ensp;不二之选</span>
          </div>
          <div
            class="button-group"
            style="
              width: 100%;
              display: flex;
              margin: auto;
              justify-content: center;
              align-items: center;
            "
          >
            <button
              ref="todayHotBt"
              class="swiper-button active"
              @click="todayHot()"
            >
              <span>今日热卖</span>
            </button>
            <button
              ref="specialRecommendBt"
              class="swiper-button"
              @click="specialRecommend()"
            >
              <span>特别推荐</span>
            </button>
            <button
              ref="newFlowersBt"
              class="swiper-button"
              @click="newFlowers()"
            >
              <span>新品上架 </span>
            </button>
            <button
              ref="discountOffersBt"
              class="swiper-button"
              @click="discountOffers()"
            >
              <span>优惠花品</span>
            </button>
          </div>
          <div style="text-align: center">
            <button class="go-shop" @click="goShop()">
              <span>前往花店</span>
            </button>
          </div>
        </div>
      </div>
    </a-carousel-item>
    <a-carousel-item
      style="border-radius: 15px"
      v-for="swiper of swipers"
      :key="swiper"
    >
      <div class="swiper-bg">
        <img
          :src="swiper"
          alt=""
          style="width: 100%; -webkit-user-drag: none"
        />
      </div>
    </a-carousel-item>
  </a-carousel>
</template>
<script setup>
/* <a-carousel-item style="border-radius: 15px">
      <!-- todo -->
      <div class="swiper-bg2">
        <div class="swiper-item2">
          <img src="../assets/swiperbg2.jpeg" alt="" style="width: 100%" />
        </div>
      </div>
    </a-carousel-item>
    <a-carousel-item style="border-radius: 15px">
      <div class="swiper-bg3">
        <img
          src="../assets/swiperbg3.png"
          alt=""
          style="width: 100%; -webkit-user-drag: none"
        />
      </div>
    </a-carousel-item> */
import store from "@/store";
import { computed, ref } from "vue";

const todayHotBt = ref();
const newFlowersBt = ref();
const discountOffersBt = ref();
const specialRecommendBt = ref();
const swipers = computed(() => store.state.appConfig.swipers);
const emit = defineEmits([
  "getTodayHot",
  "getSpecialRecommend",
  "getNewFlowers",
  "getDiscountOffers",
]);
const handleChange = (value) => {
  //todo动感切换
};
const todayHot = () => {
  todayHotBt.value.classList.add("active");
  specialRecommendBt.value.classList.remove("active");
  newFlowersBt.value.classList.remove("active");
  discountOffersBt.value.classList.remove("active");

  emit("getTodayHot");
};
const specialRecommend = () => {
  specialRecommendBt.value.classList.add("active");
  todayHotBt.value.classList.remove("active");
  newFlowersBt.value.classList.remove("active");
  discountOffersBt.value.classList.remove("active");
  emit("getSpecialRecommend");
};
const newFlowers = () => {
  newFlowersBt.value.classList.add("active");
  specialRecommendBt.value.classList.remove("active");
  todayHotBt.value.classList.remove("active");
  discountOffersBt.value.classList.remove("active");
  emit("getNewFlowers");
};
const discountOffers = () => {
  discountOffersBt.value.classList.add("active");
  specialRecommendBt.value.classList.remove("active");
  newFlowersBt.value.classList.remove("active");
  todayHotBt.value.classList.remove("active");
  emit("getDiscountOffers");
};
const goShop = () => {
  window.open("/flowers", "_self");
};

{
  /* <div class="item3-top" style="height: 50%">
          <div class="thanks">
            <div class="thanks-font">
              感谢选择<span style="color: var(--my-color)">半缘花意</span>
            </div>
            <!-- <img class="thanks-img" src="../assets/artFont.png" /> -->
          </div>
        </div>
        <!-- todo -->
        <div class="item3-btm" style="height: 50%">
          <div class="poem-item">
            <span>曾经沧海难为水，</span>
            <span>除却巫山不是云。</span>
          </div>
          <p></p>
          <div class="poem-item">
            <span>取次花丛懒回顾，</span>
            <span><span>半缘花意</span>半缘君。</span>
          </div>
          <div class="btm-btm">
            <div class="slogan">
              <p>因为花意，更因为爱你</p>
            </div>
            <div class="motto">
              <p>一束花，一份爱。</p>
            </div>
          </div>
        </div> */
}
</script>

<style scoped>
.active {
  background-color: var(--my-color) !important;
  color: #fff !important;
}
.swiper-bg1,
.swiper-bg2,
.swiper-bg {
  width: 100%;
  height: 100%;
}
.artistic-text {
  font-family: "Courier New", Courier, monospace;
  letter-spacing: 0.5vw;
  user-select: none;
  background: linear-gradient(#ff6ec4, #7873f5); /* 渐变背景色 */
  -webkit-background-clip: text; /* 对于 Safari 和 Chrome */
  -webkit-text-fill-color: transparent; /* 对于 Safari 和 Chrome */
  background-clip: text; /* 对于 Firefox */
  color: transparent; /* 对于 Firefox */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* 设置文本阴影 */
  font-size: 2vw; /* 设置字体大小 */
  width: 100%; /* 让字体宽度随父容器变化 */
}
.swiper-button {
  display: flex;
  background-color: transparent;
  border: var(--my-color) solid 1px;
  color: var(--my-color); /* 文字颜色 */
  cursor: pointer; /* 鼠标指针 */
  padding: 0.8vw 2vw;
  transition: background-color 0.3s ease; /* 过渡效果 */
  border-radius: 20px;
  flex: 1;
  margin: auto 5%;
}
button > span {
  font-size: 1vw;
  margin: auto;
}
@media screen and (min-width: 601px) {
  .swiper-button:hover {
    background-color: var(--my-color); /* 鼠标经过时背景色 */
    color: #fff; /* 鼠标经过时文字颜色 */
  }
}
@media screen and (max-width: 600px) {
  button > span {
    font-size: 1vh;
  }
}
.go-shop {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--my-color);
  color: #fff;
  border: var(--my-color) solid 1px;
  cursor: pointer; /* 鼠标指针 */
  transition: background-color 0.3s ease; /* 过渡效果 */
  border-radius: 20px;
  padding: 0.8vw 2vw;
  margin: 2% auto;
}
.artistic-text span {
  font-family: cursive;
  color: #ff5722 !important; /* 设置不同的颜色 */
  -webkit-background-clip: initial; /* 重置背景剪裁 */
  -webkit-text-fill-color: initial; /* 重置文本填充颜色 */
  background-clip: initial; /* 重置背景剪裁 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* 设置文本阴影 */
}

.arco-carousel-arrow > div > svg {
  color: var(--my-color) !important;
}
.item3-top {
  display: flex;
  justify-content: center;
  align-items: center;
}
.thanks {
  width: 80%;
  margin: auto;
}
.thanks-font {
  font-family: STXingkai;
  font-size: 7vw;
  display: flex;
}
.thanks-img {
  width: 50%;
  margin: auto;
}

.item3-btm {
  margin: auto;
  letter-spacing: 0.5vw;
  font-size: 1.5vw;
  font-family: "Courier New", Courier, monospace;
  font-weight: lighter;
}
.item3-btm span span {
  color: var(--my-color);
}
.btm-btm {
  margin: auto;
  font-family: "Courier New", Courier, monospace;
}
.slogan {
  margin: auto;
  letter-spacing: 0.5vw;
}

.motto {
  font-weight: bold;
}
</style>
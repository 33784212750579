<template>
    <div v-if="isLogin"></div>
    <div v-else>
        <a-modal v-model:visible="visible" @ok="handleOk" :closable="false" :mask-closable="false" @cancel="handleOk">
    <template #title>
      提示
    </template>
    <div>请您先进行登录！</div>
  </a-modal>
    </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps(["isLogin"]);
const visible = ref(true)
const handleOk = () =>{
    window.open("/","_self")
}
</script>


<style>


</style>
<template>
  <div>
    <span
      v-if="!canEditUsername"
      style="font-size: 1.2vw; margin-left: calc(1em)"
      >{{ username }}</span
    >
    <a-input
      ref="editUsernameInput"
      v-else
      v-model="editUsername"
      allow-clear
      @click.stop
      @blur.stop="handleUpdateUsername"
      @keydown.enter="handleUpdateUsername"
    />
    <a-tooltip content="用户名30天可修改一次"
      ><icon-edit
        @click.stop="updateUsername()"
        style="margin-left: 0.5vw; cursor: pointer"
    /></a-tooltip>
  </div>
</template>
<script setup>
import store from "@/store";
import axios from "axios";
import { computed, ref, watch } from "vue";

const username = computed(() => {
  return store.state.username;
});
const canEditUsername = ref(false);
const editUsernameInput = ref();
const editUsername = ref(username.value);
const updateUsername = async () => {
  try {
    const { data } = await axios.get("/user/canUpdateUsername");
    if (data.code == 0) {
      //改用户名
      await setCanEditUsername(true);
      editUsernameInput.value.focus();
    } else {
      Message.warning(data.msg);
    }
  } catch (error) {}
};
// 监听 Vuex store 中 username 的变化，并更新 editUsername
watch(username, (newUsername) => {
  editUsername.value = newUsername;
});
const setCanEditUsername = async (flag) => {
  canEditUsername.value = flag;
};
const modalLock = ref(false);
const handleUpdateUsername = async () => {
  if (!modalLock.value) {
    modalLock.value = true;
    Modal.open({
      title: "确认",
      content: `确定要将用户名修改为“${editUsername.value}”吗？`,
      onOk: () => {
        store.commit("updateUsername", editUsername.value);

        axios
          .post("/user/updateUsername", editUsername.value, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then((res) => {
          });
      },
    });
    canEditUsername.value = false;
  }
};
</script>
<style scoped>
@media screen and (max-width: 600px) {
  span{
    font-size: 2vh !important;
  }
}
</style> 
import { createStore } from 'vuex'

export default createStore({
  state: {
    isLogin: false,
    username:'user',
    avatarUrl:'https://via.placeholder.com/80x80?text=user',
    userId:0,
    host:'myfloral040626.yiban.love',
    appConfig:{
      swipers:[],
      beian:'',
      wx_qrcode:"",
      copyRight:"",
      floral_info_source:"",
      domain:"myfloral040626.yiban.love"
    }
  },
  getters: {
  },
  mutations: {
    updateLoginStatus(state, status) {
      state.isLogin = status;
    },
    updateUsername(state,username){
      state.username = username;
    },
    updateAvatarUrl(state,avatarUrl){
      state.avatarUrl = avatarUrl;
    },
    updateUserId(state,userId){
      state.userId = userId;
    },
    updateAppConfig(state, appConfig) {
      state.appConfig = appConfig;
    },
  },
  actions: {
  },
  modules: {
  }
})

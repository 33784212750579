<template>
  <a-modal
    v-if="!isLogin"
    v-model:visible="modalVisible"
    :closable="false"
    @ok="gotoIndex"
    title="未登录"
    >请先回首页进行登录</a-modal
  >
  <div v-else class="myself-container">
    <div class="myself-show-logo">
      <div class="logo">
        <img :src="appconfig.site_logo" alt="" width="100%" height="100%" />
      </div>
      <a-page-header
        style="flex: auto"
        title="半缘花意"
        subtitle="个人主页"
        @back="backToLast"
      >
        <template #extra>
          <div class="avatar-head">
            <a-tooltip :content="username"
              ><a-avatar :size="50"
                ><img :src="avatarSrc" alt="avatar" /></a-avatar
            ></a-tooltip>
          </div>
        </template>
      </a-page-header>
    </div>
    <a-divider style="margin: 0 auto"></a-divider>
    <div class="myself-main">
      <div class="menu">
        <div
          class="my-info menu-item active"
          @click="showMenu('myInfo')"
          id="my-info"
        >
          个人信息
        </div>
        <a-divider margin="0" />
        <div
          class="order-info menu-item"
          @click="showMenu('orderInfo')"
          id="order-info"
        >
          订单信息
        </div>
        <a-divider margin="0" />
        <div
          class="setting menu-item"
          @click="showMenu('setting')"
          id="setting"
        >
          设置
        </div>
        <a-divider margin="0" />
        <div
          class="contact menu-item"
          @click="showMenu('contact')"
          id="contact"
        >
          联系我们
        </div>
      </div>
      <a-divider direction="vertical" margin="0"></a-divider>
      <div class="menu-pages">
        <div class="menu-page my-info" v-show="myInfoShow">
          <upload-avatar style="margin-top: 2%"></upload-avatar>
          <upload-name style="margin-top: 1%"></upload-name>
          <div></div>
        </div>
        <div class="menu-page order-info" v-show="orderInfoShow">
          <div class="order-info-tabs">
            <div class="tab-wrapper">
              <!--tab section 1-->
              <input
                type="radio"
                name="tab-radio"
                class="tab-radio"
                id="tab-radio-1"
                checked
                @change="changeRadioValue(null)"
              />
              <label
                for="tab-radio-1"
                class="tab-handler tab-handler-1"
                @click="getAllOrders"
                >全部</label
              >
              <a-divider direction="vertical" margin="0" />
              <!--tab section 2-->
              <input
                type="radio"
                name="tab-radio"
                class="tab-radio"
                id="tab-radio-2"
                @change="changeRadioValue(0)"
              />
              <label
                for="tab-radio-2"
                class="tab-handler tab-handler-2"
                @click="getWaitPayOrders"
                >待支付</label
              >
              <a-divider direction="vertical" margin="0" />
              <!--tab section 3-->
              <input
                type="radio"
                name="tab-radio"
                class="tab-radio"
                id="tab-radio-3"
                @change="changeRadioValue(1)"
              />
              <label
                for="tab-radio-3"
                class="tab-handler tab-handler-3"
                @click="getPaidOrders"
                >已支付</label
              ><a-divider direction="vertical" margin="0" />
              <!--tab section 4-->
              <input
                type="radio"
                name="tab-radio"
                class="tab-radio"
                id="tab-radio-4"
                @change="changeRadioValue(2)"
              />
              <label
                for="tab-radio-4"
                class="tab-handler tab-handler-4"
                @click="getFinishOrders"
                >已完成</label
              >
            </div>
          </div>
          <div class="orders-table">
            <a-spin
              :loading="orderInfoLoading"
              dot
              style="display: flex; flex-direction: column"
            >
              <div class="orders-info-list">
                <div
                  class="order-item"
                  v-for="order of ordersInfo"
                  style="margin: 2% auto"
                  :key="order.id"
                >
                  <!-- <div class="order-tip">{{ orderTip }}</div> -->
                  <div class="order-img">
                    <img
                      :src="order.floralImg"
                      alt=""
                      style="aspect-ratio: 1/1"
                      width="100%"
                    />
                  </div>
                  <div class="order-content">
                    <div class="order-info">
                      <div class="order-orderNo">
                        <label>订单编号：</label>{{ order.orderNo }}
                      </div>
                      <div class="order-floralName">
                        <label>购买花品：</label>{{ order.floralName }}
                      </div>
                      <div class="order-price">
                        <label>支付金额：</label
                        ><span
                          style="
                            font-size: 2vh;
                            color: #ff5722;
                            font-style: oblique;
                            font-weight: bold;
                          "
                          >￥{{ order.orderAmount }}</span
                        >
                      </div>
                    </div>
                    <div class="order-action">
                      <a-space v-show="order.status == 0">
                        <button
                          class="order-action-button remove-order"
                          @click="removeOrder(order)"
                        >
                          取消订单
                        </button>
                        <button
                          class="order-action-button pay-order"
                          @click="payOrder(order)"
                        >
                          去支付
                        </button>
                      </a-space>
                      <a-space v-show="order.status == 1">
                        <button
                          class="order-action-button detail"
                          @click="OrderDetial(order)"
                        >
                          查看详情</button
                        ><button
                          class="order-action-button finish-update"
                          @click="OrderFinishUpdate(order)"
                        >
                          确认收货
                        </button>
                      </a-space>
                    </div>
                  </div>
                </div>
              </div></a-spin
            >
            <a-pagination
              style="position: absolute; bottom: 0"
              :total="pageParam.total"
              simple
              @change="changePage"
            />
          </div>
        </div>
        <div class="menu-page setting" v-show="settingShow"></div>
        <div class="menu-page contact" v-show="contactShow"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import UploadAvatar from "@/components/UploadAvatar.vue";
import UploadName from "@/components/UploadName.vue";
import router from "@/router";
import store from "@/store";
import { Message, Modal } from "@arco-design/web-vue";
import axios from "axios";
import { nextTick, onUpdated, ref, watch } from "vue";
import { onMounted } from "vue";
import { computed } from "vue";

document.title = "个人主页";
const props = defineProps(["isLogin"]);
const avatarSrc = computed(() => store.state.avatarUrl);
const username = computed(() => store.state.username);
const userId = computed(() => store.state.userId);
const myInfoShow = ref(true);
const orderInfoShow = ref(false);
const settingShow = ref(false);
const contactShow = ref(false);
const orderTip = ref("商品等待付款中");
const ordersInfo = ref([]);
const waitPayOrders = ref([]);
const allOrders = ref([]);
const paidOrders = ref([]);
const finishOrders = ref([]);
const pageParam = ref({
  total: 0,
});
const orderInfoLoading = ref(true);
const radioCurrentValue = ref(null);
const waitPayOrdersFlag = ref(false);
const allOrdersFlag = ref(false);
const paidOrdersFlag = ref(false);
const appconfig = computed(()=>store.state.appConfig)
const finishOrdersFlag = ref(false);
const modalVisible = computed(() => !props.isLogin);
watch(myInfoShow, async (newValue, oldValue) => {
  if (myInfoShow.value) {
    await removeMenuItemActive();
    document.querySelector(".menu-item.my-info").classList.add("active");
  }
});
watch(orderInfoShow, async (newValue, oldValue) => {
  if (orderInfoShow.value) {
    await removeMenuItemActive();
    document.querySelector(".menu-item.order-info").classList.add("active");
  }
});
watch(settingShow, async (newValue, oldValue) => {
  if (settingShow.value) {
    await removeMenuItemActive();
    document.querySelector(".menu-item.setting").classList.add("active");
  }
});
watch(contactShow, async (newValue, oldValue) => {
  if (contactShow.value) {
    await removeMenuItemActive();
    document.querySelector(".menu-item.contact").classList.add("active");
  }
});
// onUpdated(async () => {
//   document.querySelectorAll(".menu-item").forEach((e) => {
//     e.addEventListener("click", async (e) => {
//       await removeMenuItemActive();
//       e.target.classList.add("active");
//     });
//   });
// });
onMounted(() => {
  const idName = window.location.href.split("#")[1];
  const loading = Message.loading("请稍等");
  if (idName == "orders") {
    setTimeout(() => {
      showMenu("orderInfo");
    }, 1000);
  }
  loading.close();
});
const gotoIndex = async () => {
  window.open("/", "_self");
};
const showMenu = async (page) => {
  await setShowPageFalse();
  switch (page) {
    case "myInfo":
      myInfoShow.value = true;
      break;
    case "orderInfo":
      orderInfoShow.value = true;
      getAllOrders();
      break;
    case "setting":
      settingShow.value = true;
      break;
    case "contact":
      contactShow.value = true;
      break;
    default:
      break;
  }
};
const changeRadioValue = (value) => {
  radioCurrentValue.value = value;
};
const backToLast = () => {
  if (window.history.length > 1) {
    window.history.back(); // 返回上一页
  } else {
    window.close();
  }
};

const requestOrders = async (url, flag, cacheVar) => {
  orderInfoLoading.value = true;
  if (flag.value) {
    // 如果已经请求过,直接使用缓存数据
    ordersInfo.value = cacheVar.value.records;
    pageParam.value.total = cacheVar.value.total;

    orderInfoLoading.value = false;
    return;
  }

  try {
    const { data } = await axios.post(url);
    if (data.code === 0) {
      cacheVar.value = data.data;
      ordersInfo.value = data.data.records;
      pageParam.value.total = data.data.total;
      flag.value = true;
      return data.data;
    }
  } catch (error) {
    console.error(error);
  } finally {
    orderInfoLoading.value = false;
  }
};

const getAllOrders = () => {
  return requestOrders(`/orders/${userId.value}`, allOrdersFlag, allOrders);
};

const getWaitPayOrders = () => {
  return requestOrders(
    `/orders/${userId.value}/0`,
    waitPayOrdersFlag,
    waitPayOrders
  );
};

const getPaidOrders = () => {
  return requestOrders(`/orders/${userId.value}/1`, paidOrdersFlag, paidOrders);
};

const getFinishOrders = () => {
  return requestOrders(
    `/orders/${userId.value}/2`,
    finishOrdersFlag,
    finishOrders
  );
};
const removeOrder = (order) => {};
const payOrder = (order) => {};
const OrderDetial = (order) => {};
const OrderFinishUpdate = (order) => {};
const changePage = (current) => {
  if (radioCurrentValue.value != null) {
    axios
      .post(
        `/orders/${userId.value}/${radioCurrentValue.value}?current=${current}`
      )
      .then(({ data }) => {
        if (data.code == 0) {
          ordersInfo.value = data.data.records;
        }
      });
  } else {
    axios
      .post(`/orders/${userId.value}?current=${current}`)
      .then(({ data }) => {
        if (data.code == 0) {
          ordersInfo.value = data.data.records;
        }
      });
  }
};
const setShowPageFalse = async () => {
  myInfoShow.value = false;
  orderInfoShow.value = false;
  settingShow.value = false;
  contactShow.value = false;
};
const removeMenuItemActive = async () => {
  document.querySelectorAll(".menu-item").forEach((e) => {
    e.classList.remove("active");
  });
};
</script>
<style scoped>
.orders-info-list .order-item {
  width: 40%;
  display: flex;
  flex-direction: row;
  background-color: var(--color-neutral-2);
  border-radius: 15px;
  align-items: center;
  overflow: hidden;
  height: 18vh;
}
.order-item .order-img {
  flex: 0.3;
  display: flex;
}
.order-item .order-content {
  flex: 0.7;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.order-content .order-info {
  width: 90%;
  text-align: left;
  display: flex;
  flex: 0.9;
  flex-direction: column;
  justify-content: space-around;
}
.order-content .order-action {
  width: 90%;
  text-align: end;
}
.order-action .order-action-button {
  border: none;
  cursor: pointer;
  width: 10vh;
}
.order-action-button.remove-order {
  background-color: rgb(var(--red-1));

  border: 1px solid rgb(var(--red-6));
  color: #717181;
}
.order-action-button.pay-order {
  background-color: #fa1d61;
  color: #fff;
}
.order-action-button.detail {
  background-color: var(--color-neutral-2);
  border: 1px solid var(--color-neutral-4);
}
.order-action-button.finish-update {
  background-color: rgb(var(--green-2));
  border: 1px solid rgb(var(--green-6));
  color: #717181;
}
.myself-container {
  height: 100vh;
  width: 90vw;
  background-color: #fff;
  margin: 0 auto;
  /* overflow-y: scroll; */
  overflow: hidden;
}
@media screen and (min-width: 601px) {
  .orders-info-list .order-item {
    margin-left: 5% !important;
  }
  .order-action .order-action-button:hover {
    transform: scale(1.1);
  }
}
@media screen and (max-width: 600px) {
  .myself-container {
    width: 100vw;
  }
  .avatar-head {
    display: none;
  }
  .myself-main .menu {
    flex: 0.3 !important;
  }
  .order-orderNo {
    display: none;
  }
  .order-item {
    width: 100% !important;
    margin-left: 0 !important;
    height: auto !important;
    aspect-ratio: 3/1.3;
  }
  .orders-info-list {
    margin-bottom: 20% !important;
  }
  .order-action-button {
    font-size: 2vh;
    padding: 5px 0;
  }
}
.myself-show-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 15vh;
}
.myself-show-logo .logo {
  height: 80%;
  margin-left: 5%;
}
.myself-main {
  display: flex;
  flex-direction: row;
  height: 85vh;
}
.myself-main .menu {
  flex: 0.15;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 2%;
}
.myself-main .menu-pages {
  flex: 0.85;
}
.menu-item {
  width: 80%;
  background-color: var(--color-neutral-4);
  line-height: 5vh;
  border-radius: 15px;
}
.menu-item.active {
  background-color: var(--my-color);
  color: #fff;
}
/* Hide the scrollbar but allow scrolling */
.orders-table::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.orders-table {
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  scrollbar-width: none; /* For Firefox */
}
.menu-page {
  height: 100%;
}
.menu-pages > .order-info {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
}
.order-info-tabs {
  flex: 0.1;
}
.orders-table {
  flex: 0.9;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}
.orders-info-list {
  flex: 0.9;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5%;
}
.tab-wrapper {
  position: relative;
  display: flex;
  justify-content: space-around;
}
.tab-wrapper .tab-radio {
  display: none;
}
.tab-handler {
  position: relative;
  z-index: 2;
  height: 6vh;
  padding: 0 3vw;
  color: #717181;
  font-size: 2vh;
  line-height: 6vh;
  transition: 0.3s;
  transform: scale(0.9);
  border-radius: 15px;
}
.tab-radio:checked + .tab-handler {
  color: #fff;
  background-color: var(--my-color);
  transform: scale(1);
}
.tab-radio:checked + .tab-handler + .tab-content {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.tab-wrapper .tab-content {
  visibility: hidden;
  position: absolute;
  top: 6vh;
  left: 0;
  padding: 2vw;
  opacity: 0;
  transition: transform 0.5s, opacity 0.7s;
  transform: translateY(20px);
}
</style>
<template>
  <a-modal
    :visible="visible"
    title="登录"
    :footer="!wxLoginVisible"
    modal-class="login-modal"
    @cancel="handleCancel"
    @ok="handleConfirm"
  >
    <template v-if="wxLoginVisible" #title>
      <a-button class="return-login-by-tel" @click="returnLoginByTel"
        >返回手机号登录</a-button
      >
    </template>
    <a-form
      v-show="!wxLoginVisible"
      ref="loginFormRef"
      :model="loginForm"
      :rules="loginRules"
    >
      <a-form-item field="telnumber" label="电话号码" show-colon>
        <a-input v-model="loginForm.telnumber" placeholder="请输入电话号码">
          <template #prepend> +86 </template>
        </a-input>
      </a-form-item>
      <a-form-item field="code" label="验证码" show-colon>
        <a-input v-model="loginForm.code" placeholder="请输入验证码">
          <template #append>
            <a-button
              style="width: auto"
              class="getCodeButton"
              :disabled="buttonDisabled"
              @click="getVerifyCode"
              >{{ verifyCodeButtonText }}</a-button
            >
          </template>
        </a-input>
      </a-form-item>
      <div
        style="color: var(--my-color); font-style: italic; text-align: center"
      >
        未注册用户将自动注册登录
      </div>
      <div class="weixin-login" style="text-align: center">
        <a-tooltip content="点击进行微信扫码登陆">
          <img
            src="../assets/微信.png"
            @click="wxLoginByQRCode"
            alt=""
            style="width: 4vh; position: relative; top: 2vh"
          />
        </a-tooltip>
      </div>
    </a-form>
    <div
      v-show="wxLoginVisible"
      class="wx-QRCode-div"
      style="text-align: center"
    ></div>
  </a-modal>
</template>

<script setup>
import { Message } from "@arco-design/web-vue";
import axios from "axios";
import { ref, computed } from "vue";
import { useStore } from "vuex";
const wxLoginVisible = ref(false);
const store = useStore();

const props = defineProps({
  visible: Boolean,
});
const loginForm = ref({
  telnumber: "",
  code: "",
});
const loginFormRef = ref();
const loginRules = {
  telnumber: [
    { required: true, message: "手机号不能为空" },
    {
      match: /^1[3456789]\d{9}$/,
      message: "请输入正确的手机号码",
    },
  ],
  code: [
    {
      required: true,
      message: "验证码不能为空",
    },
    {
      match: /^\d{6}$/,
      message: "请输入6位数字验证码",
    },
  ],
};

const verifyCodeButtonText = ref("获取验证码");
const buttonDisabled = ref(false);

const emit = defineEmits(["update:visible", "loginCancel", "loginConfirm"]);

const handleCancel = () => {
  if (!wxLoginVisible.value) {
    loginFormRef.value.resetFields();
  } else {
    document.querySelector(".wx-QRCode").remove();
    wxLoginVisible.value = false;
  }
  emit("update:visible", false);
  emit("loginCancel");
};

const handleConfirm = async () => {
  loginFormRef.value.validate((errors) => {
    if (errors) {
      Message.error("表单填写有误，请仔细检查！");
    } else {
      //提交表单
      let logining = Message.loading("登陆中");

      axios
        .post("/user/login", JSON.stringify(loginForm.value), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async (res) => {
          if (res.data.code == 0) {
            // 假设res.headers.authorization包含了token信息
            console.log(res);
            const token = res.headers.authorization;
            // 设置token，过期时间为1天
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 1);
            const expires = "expires=" + expirationDate.toUTCString();

            await setCookie("token", token, expires);

            const refreshToken = res.headers["refresh-token"];
            // 设置longtoken，过期时间为2天
            const longExpirationDate = new Date();
            longExpirationDate.setDate(longExpirationDate.getDate() + 1);
            const longExpires = "expires=" + longExpirationDate.toUTCString();

            await setCookie("longToken", refreshToken, longExpires);

            emit("update:visible", false);
            emit("loginConfirm");
            const { data } = await axios.post("/user/verifyToken", null, {
              headers: {
                Authorization: token,
              },
            });
            if (data.code == 0) {
              logining.close();
              Message.success("已登录！");
              store.commit("updateLoginStatus", true);
              store.commit("updateUserId", data.data.id);
              store.commit("updateUsername", data.data.username);
              store.commit("updateAvatarUrl", data.data.avatarUrl);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });
};
const setCookie = async (key, token, expires) => {
  document.cookie = key + "=" + token + ";" + expires + ";path=/";
};
const getVerifyCode = () => {
  loginFormRef.value.validateField("telnumber", (errors) => {
    if (errors) {
      Message.error("请检查电话号码填写是否正确！");
    } else {
      axios
        .get(`/user/verifyCode?telnumber=${loginForm.value.telnumber}`)
        .then((res) => {
          if (res.data.code === 0) {
            Message.success("验证码发送成功！");

            // 设置按钮文本为 60 秒倒计时
            let countDown = 60;
            verifyCodeButtonText.value = `${countDown}秒后重新发送`;
            buttonDisabled.value = true;

            const timer = setInterval(() => {
              countDown--;
              if (countDown === 0) {
                // 倒计时结束，恢复按钮文本和按钮状态
                verifyCodeButtonText.value = "重新发送验证码";
                buttonDisabled.value = false;
                clearInterval(timer);
              } else {
                verifyCodeButtonText.value = `${countDown}秒后重新发送`;
              }
            }, 1000);
          } else {
            Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });
};
const wxLoginByQRCode = () => {
  // Generate a unique client ID
  const clientId = Date.now().toString();
  axios
    .post(
      `/user/wx-get-app-QRCode/${clientId}`,
      {},
      {
        responseType: "blob",
      }
    )
    .then((res) => {
      const url = URL.createObjectURL(res.data);
      const img = document.createElement("img");
      img.src = url;
      img.style.width = "20vw";
      img.className = "wx-QRCode";
      document.querySelector(".wx-QRCode-div").appendChild(img);
      wxLoginVisible.value = true;

      // Establish WebSocket connection
      const socket = new WebSocket(
        `wss://${store.state.appConfig.domain}/websocket-wx-login/web/${clientId}`
      );

      socket.onopen = () => {
        console.log("WebSocket connection established");
      };

      socket.onmessage = async (event) => {
        // 假设res.headers.authorization包含了token信息
        const token = event.data;
        // 设置cookie，过期时间为1天
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1);
        const expires = "expires=" + expirationDate.toUTCString();

        await setCookie("token", token, expires);

        emit("update:visible", false);
        emit("loginConfirm");
        document.querySelector(".wx-QRCode").remove();
        wxLoginVisible.value = false;
        const { data } = await axios.post("/user/verifyToken", null, {
          headers: {
            Authorization: token,
          },
        });
        if (data.code == 0) {
          Message.success("已登录！");
          store.commit("updateLoginStatus", true);
          store.commit("updateUsername", data.data.username);
          store.commit("updateAvatarUrl", data.data.avatarUrl);
          socket.close();
        }
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed");
      };

      socket.onerror = (error) => {
        console.log("WebSocket error:", error);
      };
    });
};

const returnLoginByTel = () => {
  document.querySelector(".wx-QRCode").remove();
  wxLoginVisible.value = false;
};
</script>
<style>
.return-login-by-tel {
  background: linear-gradient(90deg, #5170ff, #ff66c4);
  color: white !important;
}
@media screen and (min-width: 601px) {
  .getCodeButton:hover {
    color: var(--my-color) !important;
  }
}
@media screen and (max-width: 600px) {
  .login-modal {
    width: 90vw !important;
  }
  .login-modal label {
    display: none;
    font-size: 2vw !important;
  }
  #telnumber,
  #code {
    width: auto;
    flex: auto;
  }
}
</style>
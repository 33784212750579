<template>
  <div class="avatar">
    <a-tooltip content="点击进行上传头像"
      ><a-upload
        :custom-request="uploadRequest"
        :show-file-list="false"
        ref="uploadAvatar"
        accept=".png,.jpg,.ico"
        ><template #upload-button
          ><img :src="defaultAvatarSrc" alt="" /></template></a-upload
    ></a-tooltip>
  </div>
</template>
<script setup>
import store from "@/store";
import axios from "axios";
import { computed } from "vue";

const defaultAvatarSrc = computed(() => {
  return store.state.avatarUrl;
});
const uploadRequest = ({ fileItem }) => {
  const formData = new FormData();
  formData.append("file", fileItem.file);

  // 使用 Axios 发送文件上传请求
  axios
    .post("/user/avatar", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      //todo 文件
      if (data.code == 0) {
        store.commit("updateAvatarUrl", data.avatarUrl);
      }
    })
    .catch((error) => {
      console.log(error.response.data);
    });
};
</script>
<style scoped>
.avatar img {
  border-radius: 50%;
  width: 6vw;
}
@media screen and (max-width: 600px) {
  .avatar img{
    width: 10vh;
  }
}
</style>
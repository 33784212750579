<template>
  <div class="sider-content">
    <div class="sider-top">
      <div v-if="isLogin" class="user-info">
        <div class="avatar">
          <a-tooltip content="点击进行上传头像"
            ><a-upload
              :custom-request="uploadRequest"
              :show-file-list="false"
              ref="uploadAvatar"
              accept=".png,.jpg,.ico"
              ><template #upload-button
                ><img :src="defaultAvatarSrc" alt="" /></template></a-upload
          ></a-tooltip>
        </div>
        <div class="greeting">
          <span
            v-if="!canEditUsername"
            style="font-size: 1.2vw; margin-left: calc(1em)"
            >{{ username }}</span
          >
          <a-input
            ref="editUsernameInput"
            v-else
            v-model="editUsername"
            allow-clear
            @click.stop
            @blur.stop="handleUpdateUsername"
            @keydown.enter="handleUpdateUsername"
          />
          <a-tooltip content="用户名30天可修改一次"
            ><icon-edit
              @click.stop="updateUsername()"
              style="margin-left: 0.5vw; cursor: pointer"
          /></a-tooltip>

          <p></p>
          <strong>{{ currentTime() }}好</strong>
        </div>
      </div>
      <div v-else>
        <div class="sider-logo">
          <img :src="appconfig.site_logo" alt="logo" />
        </div>
        <div class="greeting">
          <strong>{{ currentTime() }}好</strong>
        </div>
        <div class="login-tip">
          <div class="tip-pre"><span>为了不影响您的体验</span></div>
          <div class="tip-aft" @click="$emit('loginClick')">
            <span>请点击这里进行登录</span>
          </div>
        </div>
      </div>
    </div>
    <div class="sider-btm">
      <!-- <div class="btm-top">
        <div class="poem">曾经沧海难为水</div>
        <div class="poem">除却巫山不是云</div>
        <div class="poem">取次花丛懒回顾</div>
        <div class="poem"><span>半缘花意</span>半缘君</div>
      </div>
      <div class="btm-btm">
        <div class="slogan">
          <p>因为花意，更因为爱你</p>
        </div>
        <div class="motto">
          <p>一束花，一份爱。</p>
        </div>
      </div> -->
      <!-- todo 关于我们 -->
      <div></div>

      <p class="copyright">
        <br />本网站鲜花信息来自<a
          :href="appconfig.floral_info_source[1]"
          style="text-decoration: none; color: var(--my-color)"
          >{{ appconfig.floral_info_source[0] }}</a
        ><br /><a-trigger
          position="top"
          auto-fit-position
          :unmount-on-close="false"
          ><span>如有侵权，请<span style="color: red">联系删除</span></span>
          <template #content>
            <img :src="appconfig.wx_qrcode" alt="" /> </template></a-trigger
        ><br /><br />
        &copy; {{ appconfig.copyright }}<br /><a
          style="text-decoration: none; color: var(--my-color)"
          href="https://beian.miit.gov.cn/shouye.html"
          target="_blank"
          >{{ appconfig.beian }}</a
        >

        <!-- <a-trigger position="top" auto-fit-position :unmount-on-close="false">
          <span>Hover Me</span>
          <template #content>
            <div class="demo-basic">
              <a-empty />
            </div>
          </template>
        </a-trigger> -->
      </p>
      <!-- 版权声明 -->
    </div>
  </div>
  <!-- -->
</template>
<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { computed } from "vue";
import store from "@/store";
import { watch } from "vue";
import { Message, Modal } from "@arco-design/web-vue";
const props = defineProps(["isLogin"]);
const canEditUsername = ref(false);
const username = computed(() => {
  return store.state.username;
});
let temp = username.value;
const editUsername = ref(temp);
const defaultAvatarSrc = computed(() => {
  return store.state.avatarUrl;
});
const uploadAvatar = ref();
const editUsernameInput = ref();
const currentTime = ref(() => {
  const hour = new Date().getHours();
  if (hour < 12) {
    return "早上";
  } else if (hour < 18) {
    return "下午";
  } else {
    return "晚上";
  }
});
const appconfig = computed(() => store.state.appConfig);
const updateUsername = async () => {
  try {
    const { data } = await axios.get("/user/canUpdateUsername");
    if (data.code == 0) {
      //改用户名
      await setCanEditUsername(true);
      editUsernameInput.value.focus();
    } else {
      Message.warning(data.msg);
    }
  } catch (error) {}
};
// 监听 Vuex store 中 username 的变化，并更新 editUsername
watch(username, (newUsername) => {
  editUsername.value = newUsername;
});
const setCanEditUsername = async (flag) => {
  canEditUsername.value = flag;
};
const modalLock = ref(false);
const handleUpdateUsername = async () => {
  if (!modalLock.value) {
    modalLock.value = true;
    Modal.open({
      title: "确认",
      content: `确定要将用户名修改为“${editUsername.value}”吗？`,
      onOk: () => {
        if (editUsername.value == "") {
          Message.error("用户名不能为空");
          return;
        }
        store.commit("updateUsername", editUsername.value);

        axios
          .post("/user/updateUsername", editUsername.value, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then((res) => {
            if (res.data.code == 0) {
              Message.success("修改成功");
            } else Message.error(res.data.msg);
          })
          .catch(() => {});
      },
    });
    canEditUsername.value = false;
  }
};
const uploadRequest = ({ fileItem }) => {
  const formData = new FormData();
  formData.append("file", fileItem.file);

  // 使用 Axios 发送文件上传请求
  axios
    .post("/user/avatar", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      //todo 文件
      if (data.code == 0) {
        store.commit("updateAvatarUrl", data.avatarUrl);
      }
    })
    .catch((error) => {
      console.log(error.response.data);
    });
};
</script>

<style scoped>
.sider-content {
  width: 90%;
  height: 90%;
  position: relative;
  top: 5%;
  margin: auto;
}
.user-info > .avatar img {
  border-radius: 50%;
  width: 6vw;
}
.sider-top {
  height: 50%;
}
.sider-logo {
  width: 100%;
}
.sider-logo > img {
  width: 50%;
}
.greeting {
  margin: 5%;
}

@media screen and (min-width: 601px) {
  strong:hover {
    color: var(--my-color);
    cursor: pointer;
  }
  .tip-aft:hover {
    color: var(--my-color);
    font-size: 1.5vw;
    background: linear-gradient(to right, var(--my-color), #7873f5);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.tip-pre,
.tip-aft {
  font-size: 1vw;
  margin: 5% auto;
}
.tip-aft {
  color: #7873f5;
  font-size: 1.2vw;
  background: linear-gradient(to right, #7873f5, var(--my-color));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 0.3s;
}

.sider-btm {
  height: 50%;
  position: relative;
  top: 3%;
}

.copyright {
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
  font-family: cursive;
}
</style>
<template>
  <div class="index-content">
    <div class="swiper">
      <my-swiper
        @get-today-hot="getTodayHot"
        @get-special-recommend="getSpecialRecommend"
        @get-new-flowers="getNewFlowers"
        @get-discount-offers="getDiscountOffers"
      />
    </div>
    <div class="main">
      <div class="main-container">
        <div class="floral-items">
          <div
            class="floral-bg"
            v-for="item in floralItems"
            :key="item.id"
            @click="floralShowPage(item.id)"
          >
            <div class="floral-img">
              <a-image
                :src="item.imageUrls[0]"
                alt=""
                width="100%"
                height="100%"
                draggable="false"
                :preview="false"
                fit="cover"
              />
            </div>
            <div class="floral-info">
              <div class="floral-price">
                <span>￥{{ item.price }}</span>
              </div>
              <div class="floral-name">
                <strong>鲜花/{{ item.name }}</strong>
                <add-love-or-cart class="add-love-cart" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <p class="source-statement">
        本网站鲜花信息来自<a
          :href="appconfig.floral_info_source[1]"
          style="text-decoration: none; color: var(--my-color)"
          >{{ appconfig.floral_info_source[0] }}</a
        >
        <span
          >，如有侵权，请<a
            style="color: red; text-decoration: none"
            href="https://qiniu.yiban.love/floral/contactway/wechat.png"
            >联系删除</a
          ></span
        >
      </p>
      <p class="copyright">
        &copy; {{ appconfig.copyright }} &emsp;<a
          style="text-decoration: none; color: var(--my-color)"
          href="https://beian.miit.gov.cn/shouye.html"
          target="_blank"
          >{{ appconfig.beian }}</a
        >
      </p>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import MySwiper from "./MySwiper.vue";
import { Message } from "@arco-design/web-vue";
import AddLoveOrCart from "@/components/AddLoveOrCart.vue";
import router from "@/router";
import store from "@/store";
const floralItems = ref([]);
const appconfig = computed(()=>store.state.appConfig)
onMounted(() => {
  const loading = Message.loading("获取中");
  getFloral("today-hot", loading);
});

const getTodayHot = () => {
  const loading = Message.loading("获取中");
  getFloral("today-hot", loading);
};
const getSpecialRecommend = () => {
  const loading = Message.loading("获取中");
  getFloral("special-rec", loading);
};
const getNewFlowers = () => {
  const loading = Message.loading("获取中");
  getFloral("new-up", loading);
};
const getDiscountOffers = () => {
  const loading = Message.loading("获取中");
  getFloral("discount", loading);
};
const floralShowPage = (id) => {
  const url = router.resolve({
    path: `/show/${id}`,
  });
  window.open(url.href, "_blank");
};
const getFloral = (type, message) => {
  axios
    .get(`/floral/${type}`)
    .then((res) => {
      message.close();
      if (res.data.code == 0) {
        floralItems.value = res.data.data;
      }
    })
    .catch((err) => {
      message.close();
      Message.error("获取失败");
    });
};
</script>

<style scoped>
.index-content {
  width: 100%;
  height: 100%;
  overflow: visible;
}
.swiper {
  border-radius: 15px;
  background-color: var(--color-menu-light-bg);
}
.swiper {
  width: 100%; /* 设置宽度为父元素的100% */
  aspect-ratio: 8/3;
}

.main {
  margin-top: 1%;
}

.main-container {
  width: 100%;
  height: 100%;
}

.floral-info {
  margin-top: 2%;
}
@media screen and (min-width: 601px) {
  .floral-bg:hover {
    border: #ff5722 solid 2px;
  }
  .floral-img {
    width: 15.85vw;
    height: 75%;
    aspect-ratio: 1/1;
  }
  .floral-bg {
    background-color: var(--color-menu-light-bg);
    width: 90%;
    text-align: left;
    /* padding-bottom: 2vh; */
    margin-bottom: 2vh;
    border-radius: 15px;
    overflow: hidden;
  }
  .floral-items {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    justify-items: center;
  }
  .footer {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .floral-img {
    aspect-ratio: 10/12;
  }
  .floral-bg {
    background-color: var(--color-menu-light-bg);
    width: 90%;
    text-align: left;
    /* padding-bottom: 2vh; */
    margin-bottom: 2vh;
    overflow: hidden;
  }
  .floral-items {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-items: center;
  }

  .floral-name {
    font-size: 1vh !important;
    margin-bottom: 5px;
  }
  .floral-price > span {
    font-size: 2vh !important;
  }
  .add-love-cart {
    display: none !important;
  }
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    bottom: 0;
    font-size: 2vh;
  }
  .footer p {
    margin: 0;
    margin-top: 5px;
  }
}
.floral-price > span {
  font-size: 2vw;
  color: #ff5722;
  font-style: oblique;
  font-weight: bold;
}
.floral-name {
  font-size: 1vw;
  margin-left: 0.5vw;
  letter-spacing: 0.1vw;
  margin-top: 0.5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ArcoVue from '@arco-design/web-vue';
import Message from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css'; // 全局引入样式
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import axios from 'axios';

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}
axios.defaults.headers.common['Authorization'] = getCookie("token");
const app = createApp(App).use(store).use(router).use(ArcoVue).use(ArcoVueIcon).mount('#app')
Message._context = app._context;

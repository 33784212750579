import { createRouter, createWebHistory } from 'vue-router'
import index from '@/views/index.vue'
import IndexContent from '@/components/IndexContent.vue'
import Cart from '@/components/Cart.vue'
import Admin from '@/views/admin.vue'
import Flowers from '@/components/Flowers.vue'
import FloralShow from '@/views/FloralShow.vue'
import Myself from '@/views/Myself.vue'
const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    children: [
      {
        path: 'index', // 子路由为空，匹配默认显示的情况
        component: IndexContent
      },
      {
        path: '', // 子路由为空，匹配默认显示的情况
        component: IndexContent
      },
      {
        path: '/cart',
        component: Cart
      },
      {
        path: '/flowers',
        component: Flowers
      }
    ]
  },
  {
    path: "/show/:id",
    name: "floralShow",
    component: FloralShow,
    props:true
  },
  {
    path: "/personal",
    name: "myself",
    component: Myself,
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin
  },
]
const router = createRouter({
  history:createWebHistory(),
  routes
})

export default router

<template>
  <!-- <a-affix> -->
  <div class="nav">
    <div class="nav-left" style="display: flex">
      <div class="logo" style="position: relative; left: 15%">
        <a-image
          width="40"
          :src="appconfig.site_logo"
          :preview="false"
        />
      </div>
      <a-space
        style="width: 80%; justify-content: center; margin: auto"
        size="large"
      >
        <a-button
          v-for="button in buttons"
          :key="button.name"
          :class="[
            'menu',
            button.name,
            { active: activeButton === button.name },
          ]"
          type="text"
          @click="setActiveButton(button.name)"
        >
          {{ button.label }}
        </a-button>
      </a-space>
    </div>
    <div class="nav-right">
      <div class="searchInput nav-right-children">
        <a-input-search
          :style="{ width: '100%', borderRadius: '20px' }"
          placeholder="请输入花种"
        />
      </div>
      <div class="nav-right-children theme">
        <a-switch
          v-model="darkTheme"
          size="large"
          @change="themeChange(darkTheme, ev)"
        >
          <template #checked-icon><icon-moon-fill /></template>
          <template #unchecked-icon><icon-sun-fill /></template>
        </a-switch>
      </div>
      <div class="nav-right-children login-bt-div">
        <a-dropdown v-if="isLogin" trigger="hover">
          <a-avatar><img :src="defaultAvatarSrc" alt="avatar" /></a-avatar>
          <template #content>
            <a-doption>
              <div @click="toMyMessage">消息</div>
            </a-doption>
            <a-divider style="margin: 0" />
            <a-doption>
              <div @click="toMyself">个人主页</div>
            </a-doption>
            <a-divider style="margin: 0" />
            <a-doption>
              <div @click="logOut">退出账号</div>
            </a-doption>
          </template>
        </a-dropdown>
        <a-button v-else @click="login" class="loginBt" shape="round"
          >登录 / 注册</a-button
        >
      </div>
    </div>
  </div>
  <!-- </a-affix> -->
  <div class="container">
    <div class="content">
      <router-view class="content" :isLogin="isLogin" />
    </div>
    <div class="sider">
      <sider @login-click="login" :isLogin="isLogin" />
    </div>
    
  </div>
  <div class="login-div">
    <LoginComp
      :visible="loginModalVisible"
      @update:visible="updateLoginModalVisible"
      @loginCancel="handleLoginCancel"
      @loginConfirm="handleLoginConfirm"
    />
  </div>
</template>

<script setup>
import sider from "@/components/sider.vue";
import { onMounted, ref, computed, onUpdated } from "vue";
import LoginComp from "@/components/LoginComp.vue";
import { Message } from "@arco-design/web-vue";
import { useStore } from "vuex";
import axios from "axios";
import { useRoute } from "vue-router";
import { onBeforeMount } from "vue";
const store = useStore();

const props = defineProps(["isLogin"]);
const defaultAvatarSrc = computed(() => {
  return store.state.avatarUrl;
});
const activeButton = ref("index"); // 默认选中的按钮
const darkTheme = ref("");
const buttons = [
  { name: "index", label: "首页" },
  { name: "flowers", label: "花店" },
  { name: "cart", label: "购物车" },
];
const appconfig = computed(() => store.state.appConfig);
onBeforeMount(() => {
  //检测当前路由路径
  const route = useRoute();
  if (route.path == "/flowers") {
    activeButton.value = "flowers";
  }
  if (route.path == "/cart") {
    activeButton.value = "cart";
  }
});

onUpdated(async () => {
  //验证token
  try {
    if (props.isLogin) {
      // Message.success("已登录！");
    }
    // const cookieString = document.cookie
    //   .split("; ")
    //   .find((row) => row.startsWith("token="));

    // if (!cookieString) {
    //   store.commit("updateLoginStatus", false);
    //   Message.warning("请登录！");
    //   isTip2Login.value = true;
    //   return;
    // }

    // const token = cookieString.split("=")[1];
    // const { data } = await axios.post("/user/verifyToken", null, {
    //   headers: {
    //     Authorization: token,
    //   },
    // });
    // if (data.code == 0) {
    //   Message.success("已登录！");
    //   store.commit("updateLoginStatus", true);
    // store.commit("updateUserId", data.data.id);
    //   store.commit("updateUsername", data.data.username);
    //   store.commit("updateAvatarUrl", data.data.avatarUrl);
    // }
  } catch (response) {
    console.log(response);
  }
});
const loginModalVisible = ref(false);

const login = () => {
  loginModalVisible.value = true;
};
const loginCancel = () => {
  loginModalVisible.value = false;
};

const updateLoginModalVisible = (value) => {
  loginModalVisible.value = value;
};

const handleLoginCancel = () => {};

const handleLoginConfirm = () => {};

const toMyself = () => {
  window.open("/personal", "_blank");
};

const logOut = () => {
  document.cookie = "";
  store.commit("updateLoginStatus", false);
  Message.info("账号已退出！");
};
const setActiveButton = (name) => {
  activeButton.value = name;
  //todo 切换主界面
  window.location.href = `/${name}`;
};
const themeChange = (theme, ev) => {
  if (theme) {
    // 设置为暗黑主题
    document.body.setAttribute("arco-theme", "dark");
  }

  // 恢复亮色主题
  else {
    document.body.removeAttribute("arco-theme");
  }
};
</script>

<style>
@media screen and (min-width: 601px) {
  .nav {
    background-color: var(--color-menu-light-bg);
    height: 8vh;
    width: 100%;
    display: flex;
  }
  .nav-left {
    width: 60%;
    margin: auto;
  }
  .nav-right {
    width: 40%;
    display: flex;
    flex: 1;
    align-items: center; /* 垂直居中 */
    justify-content: flex-end; /* 水平靠右 */
    text-align: center;
  }
  .nav-right-children {
    margin-right: 5%;
  }
  .loginBt {
    background: linear-gradient(90deg, #5170ff, #ff66c4);
    color: white !important;
    width: 90%;
  }
  .container {
    width: 95%;
    height: 91vh;
    margin: 0.5vh auto;
    display: flex;
  }
  .content {
    flex: 1.5;
    border-radius: 15px;
    margin-right: 1%;
    overflow-y: auto;
  }
  /* Hide the scrollbar but allow scrolling */
  .content::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }

  .content {
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    scrollbar-width: none; /* For Firefox */
  }

  .sider {
    flex: 0.5;
    border-radius: 15px;
    background-color: var(--color-menu-light-bg);
    height: 91vh;
  }
}
@media screen and (max-width: 600px) {
  .nav {
    background-color: var(--color-menu-light-bg);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .container {
    width: 100%;
    margin: 0.5vh auto;
  }
  .content {
    border-radius: 15px;
    overflow-y: auto;
  }
  /* Hide the scrollbar but allow scrolling */
  .content::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }

  .content {
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    scrollbar-width: none; /* For Firefox */
  }

  .sider {
    display: none;
  }
  .logo {
    display: none;
  }
  .nav-right,
  .nav-left {
    background-color: var(--color-menu-light-bg);
    height: 100%;
    padding: 5px 3px 5px 3px;
  }
  .nav-right {
    display: flex;
    align-items: center; /* 垂直居中 */
    text-align: center;
  }
  .searchInput {
    flex: 1.5;
  }
  .login-bt-div {
    flex: 0.5;
  }
  .theme {
    display: none;
  }

  .loginBt {
    background: linear-gradient(90deg, #5170ff, #ff66c4);
    color: white !important;
    margin-right: 0;
  }
  
}

.menu.active {
  color: var(--my-color) !important; /* 激活状态颜色 */
}

.arco-btn.arco-btn-text.arco-btn-shape-square.arco-btn-size-medium.arco-btn-status-normal {
  font-size: 16px !important;
  color: var(--color-neutral-10);
}
.arco-input-focus {
  /* border-color: rgb(var(--danger-3)) !important; */
  border-color: var(--my-color) !important;
}
.arco-switch-checked {
  background-color: var(--my-color) !important;
}
.arco-menu-item.arco-menu-selected .arco-icon,
.arco-menu-selected.arco-menu-has-icon {
  /* color: rgb(var(--danger-3)) !important; */
  color: var(--my-color) !important;
}
.arco-menu-selected-label {
  /* background-color: rgb(var(--danger-3)) !important; */
  background-color: var(--my-color) !important;
}
.arco-menu-item.arco-menu-has-icon {
  font-size: 130%;
}
.arco-menu-item-inner.arco-menu-title {
  font-size: 100%;
}
.arco-menu-item.arco-menu-has-icon {
  width: 25% !important;
}
.arco-icon.arco-icon-sun-fill {
  color: black;
}
.arco-icon.arco-icon-moon-fill {
  color: var(--my-color);
}
.arco-carousel-indicator-wrapper-bottom {
  border-radius: inherit;
}
</style>
<template>
  <div class="floral-manager-comp-container">
    <div class="breadcrumb">
      <a-breadcrumb>
        <a-breadcrumb-item />
        <a-breadcrumb-item>花品管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="floral-manager-head">
      <div class="floral-search-admin-input">
        <a-input-search
          :style="{ width: '20vw' }"
          placeholder="请输入花品信息"
        />
      </div>
      <div class="floral-action">
        <a-button class="action-button" type="primary">查询全部</a-button>
        <a-button class="action-button" type="primary" status="danger"
          >删除</a-button
        >
        <a-button
          class="action-button"
          type="primary"
          status="success"
          @click="addButtonClick"
          >添加</a-button
        >
      </div>
    </div>
    <div class="floral-main">
      <a-table
        row-key="id"
        :columns="columns"
        :data="data"
        :row-selection="rowSelection"
        v-model:selectedKeys="selectedKeys"
        :pagination="pagination"
        :scrollbar="scrollBar"
        :scroll="scroll"
        stripe
        column-resizable
        :bordered="{ cell: true }"
      />
    </div>
    <a-modal
      v-model:visible="addFloralModalVisible"
      title="添加花品"
      @cancel="modalCancel"
      @ok="addFloralModalOk"
      width="50vw"
      :mask-closable="false"
    >
      <a-form :model="addFloralForm" auto-label-width>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item field="name" label="花名" show-colon required>
              <a-input
                v-model="addFloralForm.name"
                placeholder="请输入花名"
              /> </a-form-item
          ></a-col>
          <a-col :span="12">
            <a-form-item field="color" label="花品颜色" show-colon required>
              <a-color-picker
                v-model="addFloralForm.color"
              /> </a-form-item></a-col
        ></a-row>
        <a-form-item field="description" label="花品描述" show-colon required>
          <a-textarea
            v-model="addFloralForm.description"
            placeholder="请输入花品描述"
            auto-size
          />
        </a-form-item>
        <a-form-item field="components" label="花品组成" show-colon required>
          <a-input
            v-model="addFloralForm.components"
            placeholder="请输入花品组成"
          />
        </a-form-item>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item field="price" label="花品价格" show-colon required>
              <a-input-number
                v-model="addFloralForm.price"
                placeholder="请输入花品价格"
              >
                <template #prefix> ￥ </template></a-input-number
              >
            </a-form-item></a-col
          >
          <a-col :span="12">
            <a-form-item field="stock" label="库存" show-colon required>
              <a-input-number
                v-model="addFloralForm.stock"
                placeholder="请输入库存"
                :step="1"
                :precision="0"
                ><template #suffix>
                  <div style="position: relative; right: 2vw">朵</div>
                </template></a-input-number
              >
            </a-form-item></a-col
          ></a-row
        >
        <a-form-item label="图片集" show-colon>
          <a-upload
            multiple
            list-type="picture-card"
            :default-file-list="fileList"
            action="/floral/upload-floral-img"
            ref="uploadFloralImgRef"
            @before-upload="beforeUpload"
            :custom-request="addFloralImageUrls"
          />
        </a-form-item>
        <a-form-item field="tags" label="花品标签" show-colon>
          <a-input-tag
            v-model="addFloralForm.tags"
            placeholder="请输入花品标签(回车添加)"
            unique-value
          />
        </a-form-item>
        <a-row :gutter="18">
          <a-col :span="8">
            <a-form-item field="category" label="分类" show-colon required>
              <a-select
                :options="options"
                :field-names="fieldNames"
                v-model="addFloralForm.category"
              /> </a-form-item
          ></a-col>
          <!-- <a-col :span="6">
            <a-form-item
              field="isDailyRec"
              label="日常推荐"
              show-colon
              required
            >
              <a-switch v-model="addFloralForm.isDailyRec" /> </a-form-item
          ></a-col> -->
          <a-col :span="8"
            ><a-form-item
              field="isTodayHot"
              label="今日热卖"
              show-colon
              required
            >
              <a-switch v-model="addFloralForm.isTodayHot" /> </a-form-item
          ></a-col>
          <a-col :span="8">
            <a-form-item
              field="isSpecialRec"
              label="特别推荐"
              show-colon
              required
            >
              <a-switch
                v-model="addFloralForm.isSpecialRec"
              /> </a-form-item></a-col
        ></a-row> </a-form
    ></a-modal>
  </div>
</template>

<script setup>
import { Message } from "@arco-design/web-vue";
import axios from "axios";
import { onMounted, reactive } from "vue";
import { ref, h } from "vue";
const getFloral = () => {
  axios.get("/floral/list").then((res) => {
    if (res.data.code == 0) {
      data.value = res.data.data;
    }
  });
};
const scrollBar = ref(true);
const addFloralModalVisible = ref(false);
const selectedKeys = ref();
const fileList = ref([]);
const addFloralForm = ref({
  name: "",
  description: "",
  components: "",
  color: "#ed9db2",
  price: 0,
  imageUrls: [],
  stock: 0,
  status: 0,
  tags: [],
  category: 1,
  isDailyRec: false,
  isTodayHot: false,
  isSpecialRec: false,
  discount: 0,
});
const options = ref([]);
const fieldNames = { value: "id", label: "name" };
const modalCancel = () => {};
const addFloralModalOk = (e) => {
  axios.post("/floral/save", addFloralForm.value).then(({ data }) => {
    if (data.code == 0) {
      Message.success("添加成功！");
      getFloral();
      addFloralModalVisible.value = false;
    }
  });
};
const pagination = ref();
const scroll = ref({
  x: "150%",
  y: "100%",
});
const count = ref(0);
const uploadFloralImgRef = ref();
const addButtonClick = () => {
  addFloralModalVisible.value = true;
  axios.get("/category/get-floral-cat").then((res) => {
    if (res.data.code == 0) {
      options.value = res.data.data;
    }
  });
};
const beforeUpload = (file) => {
  const isJPG = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJPG) {
    this.$message.error("只能上传 JPG 或 PNG 格式的图片!");
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    this.$message.error("图片大小不能超过 2MB!");
    return false;
  }
  return true;
};
const addFloralImageUrls = (option) => {
  const { fileItem } = option;
  const formData = new FormData();
  formData.append("file", fileItem.file);

  // 使用 Axios 发送文件上传请求
  axios
    .post("/floral/upload-floral-img", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      //todo 文件
      if (data.code == 0) {
        addFloralForm.value.imageUrls.push(data.data.url);
        fileItem.status = "done";
        fileList.value.push(fileItem);
        count.value = count.value + 1;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
const columns = reactive([
  {
    title: "id",
    dataIndex: "id",
    width: 80,
  },
  {
    title: "花名",
    dataIndex: "name",
    width: 100,
  },
  {
    title: "描述",
    dataIndex: "description",
    width: 200,
  },
  {
    title: "成分",
    dataIndex: "components",
  },
  {
    title: "主要颜色",
    dataIndex: "color",
    width: 100,
    render: ({ record }) => {
      return h("div", {
        style: {
          backgroundColor: record.color,
          padding: "4px",
          borderRadius: "4px",
          textAlign: "center",
        },
      });
    },
  },
  {
    title: "价格",
    dataIndex: "price",
    width: 80,
  },
  {
    title: "图片",
    dataIndex: "imageUrls",
    render: ({ record }) => {
      return h(
        "div",
        { class: "floral-images" },
        record.imageUrls.map((url) =>
          h("img", {
            src: url,
            alt: "花图片",
            style: {
              width: "50px",
              height: "50px",
              marginRight: "5px",
            },
          })
        )
      );
    },
  },
  {
    title: "标签",
    dataIndex: "tags",
    render: ({ record }) => {
      return h(
        "div",
        { class: "floral-tags" },
        record.tags.map((tag) => h("span", { class: "floral-tag" }, tag))
      );
    },
  },
  {
    title: "库存",
    dataIndex: "stock",
    width: 80,
  },
  {
    title: "状态",
    dataIndex: "status",
    width: 80,
    render: ({ record }) => {
      return h(
        "span",
        {
          class: `arco-tag arco-tag-size-medium arco-tag-${
            record.status == 0 ? "green" : "red"
          } arco-tag-bordered arco-tag-checked`,
        },
        record.status == 0 ? "正常" : "异常"
      );
    },
  },
  {
    title: "分类",
    dataIndex: "category",
    width: 80,
  },
  // {
  //   title: "日常推荐",
  //   dataIndex: "isDailyRec",
  //   width: 80,
  //   render: ({ record }) => {
  //     return h(
  //       "button",
  //       {
  //         type: "button",
  //         disabled: true,
  //         ariaChecked: `${record.isDailyRec ? "true" : "false"}`,
  //         role: "switch",
  //         class: `arco-switch arco-switch-type-circle ${
  //           record.isDailyRec ? "arco-switch-checked" : ""
  //         }`,
  //       },
  //       h(
  //         "span",
  //         { class: "arco-switch-handle" },
  //         h("span", { class: "arco-switch-handle-icon" })
  //       )
  //     );
  //   },
  // },
  {
    title: "今日热门",
    dataIndex: "isTodayHot",
    width: 80,
    render: ({ record }) => {
      return h(
        "button",
        {
          type: "button",
          disabled: true,
          ariaChecked: `${record.isTodayHot ? "true" : "false"}`,
          role: "switch",
          class: `arco-switch arco-switch-type-circle ${
            record.isTodayHot ? "arco-switch-checked" : ""
          }`,
        },
        h(
          "span",
          { class: "arco-switch-handle" },
          h("span", { class: "arco-switch-handle-icon" })
        )
      );
    },
  },
  {
    title: "特别推荐",
    dataIndex: "isSpecialRec",
    width: 80,
    render: ({ record }) => {
      return h(
        "button",
        {
          type: "button",
          disabled: true,
          ariaChecked: `${record.isSpecialRec ? "true" : "false"}`,
          role: "switch",
          class: `arco-switch arco-switch-type-circle ${
            record.isSpecialRec ? "arco-switch-checked" : ""
          }`,
        },
        h(
          "span",
          { class: "arco-switch-handle" },
          h("span", { class: "arco-switch-handle-icon" })
        )
      );
    },
  },
  {
    title: "打折优惠",
    dataIndex: "discount",
    width: 80,
    render: ({ record }) => {
      return h("div", {}, record.discount == 0 ? "无" : record.discount);
    },
  },
]);
onMounted(() => {
  getFloral();
});

const data = ref([]);
const rowSelection = reactive({
  type: "checkbox",
  showCheckedAll: true,
  onlyCurrent: false,
});
</script>

<style>
.floral-manager-comp-container {
  width: 100%;
  height: 100%;
}
.breadcrumb {
  width: 90%;
  margin: auto;
  margin-top: 1vh;
  text-align: start;
}
.floral-manager-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: auto;
  margin-top: 1%;
}
.floral-search-admin-input {
}
.action-button {
  margin-left: 1vw;
}
.floral-main {
  margin-top: 1%;
}
.floral-images {
  display: flex;
  justify-content: center;
  align-items: center;
}
.floral-tags {
  display: inline-flex;
  justify-content: center;
}
.floral-tag {
  color: var(--my-color);
  background-color: rgb(var(--orangered-1));
  border: 1px solid var(--my-color);
  height: 24px;
  font-size: 12px;
  line-height: 22px;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 8px;
  font-weight: 500;
  vertical-align: middle;
  border-radius: var(--border-radius-small);
  overflow: hidden;
  white-space: nowrap;
  margin-right: 8px;
  margin-bottom: 8px;
}
</style>
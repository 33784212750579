<template>
  <div
    class="love-cart"
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2%;
    "
  >
    <a-button-group>
      <a-button class="flower-button" type="text"
        ><template #icon
          ><a-tooltip :content="!isheart ? '收藏' : '取消收藏'"
            ><icon-heart-fill
              size="1vw"
              v-if="isheart"
              @click="removeHeart()"
              style="color: red;"
            /><icon-heart
              size="2vw"
              v-else
              @click="addHeart()"
            /> </a-tooltip></template
      ></a-button>
      <a-button class="flower-button" type="text" @click="addCart()"
        ><template #icon
          ><a-tooltip content="加入购物车"
            ><img
              src="../assets/购物车 (1).png"
              style="width: 1.5vw"
              alt=""
            /> </a-tooltip></template
      ></a-button>
    </a-button-group>
  </div>
</template>
<script setup>
import { ref } from "vue";

const isheart = ref(false);

const addHeart = ()=>{}
const removeHeart = ()=>{}
const addCart = ()=>{}
</script>
<style>
.flower-button{
  width: auto !important;
  height: auto !important;
}
</style>
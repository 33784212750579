<template>
  <router-view :isLogin="isLogin" />
</template>

<script setup>
import { Message, Modal } from "@arco-design/web-vue";
import axios from "axios";
import { onBeforeMount } from "vue";
import { onMounted } from "vue";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { getCookie } from "./main";
const store = useStore();

const isLogin = computed(() => {
  return store.state.isLogin;
});

onBeforeMount(async () => {
  //检测当前路由路径
  const route = useRoute();
  if (route.path == "/admin") {
    return;
  }
  axios.get("/config/get-app-config").then((res) => {
    if (res.data.code == 0) {
      store.commit("updateAppConfig", res.data.data);
    }
  });
  //验证token
  try {
    const token = getCookie("token");

    if (!token) {
      const longToken = getCookie("longToken");
      if (!longToken) {
        store.commit("updateLoginStatus", false);
        Message.warning("请先登录！");
        return;
      } else {
        const loginLoading = Message.loading("登陆中");
        axios
          .post("/user/refreshToken", "", {
            headers: {
              "long-token": longToken,
            },
          })
          .then(async (res) => {
            console.log(res);
            const token = res.headers.authorization;
            // 设置token，过期时间为1天
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 1);
            const expires = "expires=" + expirationDate.toUTCString();
            await setCookie("token", token, expires);
            const refreshToken = res.headers["refresh-token"];
            // 设置longtoken，过期时间为2天
            const longExpirationDate = new Date();
            longExpirationDate.setDate(longExpirationDate.getDate() + 1);
            const longExpires = "expires=" + longExpirationDate.toUTCString();
            await setCookie("longToken", refreshToken, longExpires);

            const { data } = await axios.post("/user/verifyToken", null, {
              headers: {
                Authorization: token,
              },
            });
            if (data.code == 0) {
              // Message.success("已登录！");
              store.commit("updateLoginStatus", true);
              store.commit("updateUserId", data.data.id);
              store.commit("updateUsername", data.data.username);
              store.commit("updateAvatarUrl", data.data.avatarUrl);
              loginLoading.close()
            }
          });
      }
    } else {
      const { data } = await axios.post("/user/verifyToken", null, {
        headers: {
          Authorization: token,
        },
      });
      if (data.code == 0) {
        // Message.success("已登录！");
        store.commit("updateLoginStatus", true);
        store.commit("updateUserId", data.data.id);
        store.commit("updateUsername", data.data.username);
        store.commit("updateAvatarUrl", data.data.avatarUrl);
      }
    }
  } catch (response) {
    console.log(response);
  }
});
const setCookie = async (key, token, expires) => {
  document.cookie = key + "=" + token + ";" + expires + ";path=/";
};
// 解决ERROR ResizeObserver loop completed with undelivered notifications.问题的

const debounce = (fn, delay) => {
  let timer = null;

  return function () {
    let context = this;

    let args = arguments;

    clearTimeout(timer);

    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

// 解决ERROR ResizeObserver loop completed with undelivered notifications.

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);

    super(callback);
  }
};
</script>


<style>
:root {
  --my-color: rgb(237, 157, 178);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--color-neutral-10);
  user-select: none;
}
body {
  background-color: var(--color-neutral-3);
}
</style>

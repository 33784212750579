<template>
  <div class="floral-show-container">
    <!-- <div class="advertisement">
      <strong style="color: aliceblue; font-size: 4vw">广告</strong>
    </div> -->
    <div class="floral-show-logo">
      <div class="logo">
        <img :src="appconfig.site_logo" alt="" width="100%" height="100%" />
      </div>
      <a-page-header
        style="flex: auto"
        title="半缘花意"
        :subtitle="floral.name"
        @back="backToLast"
      >
        <template #extra>
          <div class="searchInput nav-right-children">
            <a-input-search
              :style="{ width: '100%', borderRadius: '20px' }"
              placeholder="请输入花种"
            />
          </div>
        </template>
      </a-page-header>
    </div>
    <a-divider style="margin: 0 auto"></a-divider>
    <div class="floral-detail">
      <div class="floral-detail-imgs">
        <a-carousel
          v-model="currentImg"
          :autoPlay="true"
          indicator-position="bottom"
          style="width: 80%; aspect-ratio: 1/1; margin: 5% auto"
          @change="imgChange"
        >
          <a-carousel-item v-for="image in floral.imageUrls" :key="image">
            <img
              :src="image"
              :style="{
                width: '100%',
                aspectRatio: '1/1',
              }"
            />
          </a-carousel-item>
        </a-carousel>
        <div class="img-list">
          <div class="img-li" v-for="image in floral.imageUrls" :key="image">
            <img
              :src="image"
              ref="imgShowed"
              alt=""
              :style="{
                width: '100%',
                aspectRatio: '1/1',
              }"
            />
          </div>
        </div>
      </div>
      <a-divider direction="vertical"></a-divider>
      <div class="floral-detail-info">
        <div class="floral-name">{{ floral.name }}</div>
        <div class="floral-description">
          {{ floral.description }}
          <div class="heart">
            <a-button class="flower-button" type="text"
              ><template #icon
                ><a-tooltip :content="!isheart ? '收藏' : '取消收藏'"
                  ><icon-heart-fill
                    size="4vh"
                    v-if="isheart"
                    style="color: red"
                    @click="removeHeart()"
                  /><icon-heart
                    size="4vh"
                    v-else
                    @click="addHeart()"
                  /> </a-tooltip></template
            ></a-button>
          </div>
        </div>
        <div class="main-info">
          <a-divider></a-divider>
          <div class="main-info-price">
            <label>价格</label>
            <span
              style="
                font-size: 4vh;
                font-weight: 550;
                color: #ff5722;
                margin-bottom: 10%;
              "
              >￥{{ floral.price }}</span
            >
          </div>
          <a-divider></a-divider>
          <div class="main-info-tags">
            <label>标签</label>
            <a-tag
              v-for="tag of floral.tags"
              :key="tag"
              color="var(--my-color)"
              bordered
              >{{ tag }}</a-tag
            >
          </div>
          <a-divider></a-divider>
          <div class="main-info-address">
            <label>配送至</label>
            <a-space style="max-width: 80%"
              ><a-input
                style="width: auto"
                v-model="buyParams.addressOnlyArea"
                placeholder="省市县(区)"
                ><template #append>
                  <div
                    class="address-append"
                    style="color: var(--color-neutral-6)"
                  >
                    省市县(区)
                  </div></template
                ></a-input
              >
              <a-input
                style="width: auto"
                v-model="buyParams.addressDetail"
                placeholder="小区门牌"
                ><template #append>
                  <div
                    class="address-append"
                    style="color: var(--color-neutral-6)"
                  >
                    小区门牌
                  </div></template
                ></a-input
              ></a-space
            >
          </div>
          <a-divider></a-divider>
          <div class="main-info-count">
            <label>购买数量</label>
            <a-input-number
              style="width: 70%"
              mode="button"
              v-model="buyParams.buyCount"
            >
            </a-input-number>
          </div>
          <a-divider></a-divider>
          <!-- <a-descriptions
            column="1"
            :label-style="{
              fontSize: '2.5vh',
              fontWeight: '550',
              color: 'var(--color-neutral-10)',
            }"
            :value-style="{ height: '20%' }"
          >
            <a-descriptions-item label="价格">
              <span
                style="
                  font-size: 4vh;
                  font-weight: 550;
                  color: #ff5722;
                  margin-bottom: 10%;
                "
                >￥{{ floral.price }}</span
              >
            </a-descriptions-item>
            <a-descriptions-item label="标签">
              <a-tag
                v-for="tag of floral.tags"
                :key="tag"
                color="var(--my-color)"
                bordered
                >{{ tag }}</a-tag
              >
            </a-descriptions-item>
            <a-descriptions-item label="配送至">
              <a-input-group
                ><a-input
                  style="width: auto"
                  v-model="buyParams.addressOnlyArea"
                  ><template #append>
                    <div style="color: var(--color-neutral-6)">
                      省市县(区)
                    </div></template
                  ></a-input
                >
                <a-input style="width: auto" v-model="buyParams.addressDetail"
                  ><template #append>
                    <div style="color: var(--color-neutral-6)">
                      小区门牌
                    </div></template
                  ></a-input
                ></a-input-group
              >
            </a-descriptions-item>
            <a-descriptions-item label="购买数量">
              <a-input-number
                style="width: auto"
                mode="button"
                v-model="buyParams.count"
              >
              </a-input-number>
            </a-descriptions-item>
          </a-descriptions> -->
        </div>
        <div class="action">
          <button class="add-cart">加入购物车</button>
          <button class="buy" @click="showOrderForm">直接购买</button>
        </div>
      </div>
    </div>
  </div>
  <login-comp
    :visible="loginModalVisible"
    @update:visible="updateLoginModalVisible"
    @loginCancel="handleLoginCancel"
    @loginConfirm="handleLoginConfirm"
  />
  <div class="order-confirm">
    <a-modal width="auto"
      v-model:visible="orderConfirmModalVisible"
      title="确认订单"
      @cancel="handleCancel"
      @ok="confirmToBuy"
      ><a-form :model="orderForm" auto-label-width>
        <a-form-item field="orderAmount" label="价格合计">
          <span style="font-size: 3vh; font-weight: 550; color: #ff5722"
            >￥{{ orderAmount }}</span
          >
        </a-form-item>
        <a-form-item field="address" label="收货地址">
          <span style="font-size: 2vh">{{ orderForm.address }}</span>
        </a-form-item>
        <a-form-item field="buyCount" label="购买数量">
          <a-input-number
            v-model:model-value="buyParams.buyCount"
            style="max-width: 60%;"
            mode="button"
          ></a-input-number>
        </a-form-item> </a-form
    ></a-modal>
  </div>
</template>

<script setup>
import LoginComp from "@/components/LoginComp.vue";
import store from "@/store";
import { Message } from "@arco-design/web-vue";
import axios from "axios";
import { computed, nextTick, onMounted, ref } from "vue";
const props = defineProps({
  id: String,
  isLogin: Boolean,
});
const orderConfirmModalVisible = ref(false);
const appconfig = computed(()=>store.state.appConfig)
const loginModalVisible = ref(false);
const isheart = ref(false);
// const floralImages = ref([
//   "https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/cd7a1aaea8e1c5e3d26fe2591e561798.png~tplv-uwbnlip3yd-webp.webp",
//   "https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/6480dbc69be1b5de95010289787d64f1.png~tplv-uwbnlip3yd-webp.webp",
//   "https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/0265a04fddbd77a19602a15d9d55d797.png~tplv-uwbnlip3yd-webp.webp",
//   "https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/24e0dd27418d2291b65db1b21aa62254.png~tplv-uwbnlip3yd-webp.webp",
// ]);
const imgShowed = ref([]);
const buyParams = ref({
  buyCount: 1,
  addressOnlyArea: "",
  addressDetail: "",
});
const floral = ref({});
const orderForm = ref({});
onMounted(() => {
  axios.get(`/floral/get-floral/${Number.parseInt(props.id)}`).then((res) => {
    if (res.data.code == 0) {
      floral.value = res.data.data;
      // 等待 Vue 完成 DOM 更新
      nextTick(() => {
        if (imgShowed.value.length > 0) {
          imgShowed.value[0].classList.add("image-showed");
          document.title = "半缘花意/" + res.data.data.name;
        }
      });
    }
  });
});
const orderAmount = computed(
  () => buyParams.value.buyCount * floral.value.price
);
const userId = computed(() => store.state.userId);
const removeHeart = () => {
  isheart.value = false;
};
const addHeart = () => {
  isheart.value = true;
};
const currentImg = ref([0]);
const imgChange = (index, preIndex) => {
  imgShowed.value[preIndex - 1].classList.remove("image-showed");
  imgShowed.value[index - 1].classList.add("image-showed");
};

const showOrderForm = async () => {
  const islogin = await judgeLogin();
  if (islogin) {
    // const price = floral.value.price * buyParams.value.buyCount;
    // const name = floral.value.name;
    // window.open(`/alipay/pay?price=${price}&name=${name}`, "_blank");
    orderForm.value = {
      buyCount: buyParams.value.buyCount,
      orderAmount: orderAmount.value,
      address: buyParams.value.addressOnlyArea + buyParams.value.addressDetail,
      userId: userId.value,
      floralId: floral.value.id,
    };
    if (orderForm.value.address == "") {
      Message.error("地址不能为空");
      return;
    }
    orderConfirmModalVisible.value = true;
  }
};

const judgeLogin = async () => {
  if (props.isLogin) {
    return true;
  } else {
    Message.info("请先登录！");
    loginModalVisible.value = true;
    return false;
  }
};

const updateLoginModalVisible = (value) => {
  loginModalVisible.value = value;
};

const handleLoginCancel = () => {};

const handleLoginConfirm = () => {};
const confirmToBuy = () => {
  const price = orderAmount.value;
  const name = floral.value.name;
  axios.post("/orders", orderForm.value, {}).then(({ data }) => {
    if (data.code == 0) {

      window.open(
        `/alipay/pay?outTradeNo=${data.data}&price=${price}&name=${name}`,
        "_blank"
      );
    }
  });
};
const backToLast = () => {
  if (window.history.length > 1) {
    window.history.back(); // 返回上一页
  } else {
    window.close();
  }
};
const handleCancel = () => {};
</script>

<style scoped>
.floral-show-container {
  height: 100vh;
  background-color: #fff;
  margin: 0 auto;
  overflow-y: scroll;
}
/* Hide the scrollbar but allow scrolling */
.floral-show-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.floral-show-container {
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  scrollbar-width: none; /* For Firefox */
}

.advertisement {
  width: 100%;
  height: 15%;
  /* background-color: black; */
}
.floral-show-logo {
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.floral-show-logo .logo {
  height: 80%;
  margin-left: 5%;
}

.floral-detail .floral-detail-imgs {
  flex: 0.4;
  height: 100%;
}
.floral-detail .floral-detail-info {
  flex: 0.6;
  height: 100%;
}

.floral-detail-info .floral-description {
  width: 90%;
  font-size: 2vh;
  height: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: italic;
}
.floral-detail-info .action {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  top: 5%;
}
.action button {
  width: 25%;
  height: 8vh;
  border: none;
  color: #fff;
}
.action .add-cart {
  background-color: #ffeded;
  border: 1px solid #fd9ab7;
  color: #fa1d61;
}
.action .buy {
  background-color: #fa1d61;
}
.main-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.main-info label {
  font-size: 2.5vh;
  font-weight: 550;
  margin-right: 5%;
}
@media screen and (max-width: 600px) {
  .floral-show-container {
    width: 100vw;
  }
  .img-list {
    display: none;
  }
  .searchInput {
    display: none;
  }
  .floral-detail {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: start;
  }

  .floral-detail-info .floral-name {
    font-size: 4vh;
    font-family: "Times New Roman", Times, serif;
    height: 10%;
    margin-top: 5%;
  }
  .floral-detail .floral-detail-info {
    width: 95%;
    margin: auto;
  }
  .address-append {
    display: none;
  }
  .action button {
    width: 40% !important;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 601px) {
  .floral-detail-info .floral-name {
    font-size: 2vw;
    font-family: "Times New Roman", Times, serif;
    height: 10%;
    margin-top: 5%;
  }
  .floral-show-container {
    width: 90vw;
  }
  .img-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .img-li {
    margin: auto 2%;
    width: 20%;
  }
  .image-showed {
    box-shadow: 5px 5px 5px var(--my-color);
  }
  .floral-detail {
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: space-around;
    text-align: start;
  }
}
</style>
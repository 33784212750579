<template>
  <div class="flowers-container">
    <div class="flowers-tabs">
      <a-tabs
        position="top"
        type="rounded"
        animation
        justify
        v-model="tabIndex"
        @change="tabsChange"
      >
        <a-tab-pane v-for="category of categories" :key="category.id">
          <template #title>
            <div
              class="tab-title"
              @click="setActiveTabs(category.id)"
              :id="'id' + category.id"
              ref="tabPane"
              style="
                color: var(--color-neutral-10);
                font-size: 1.2vw;
                font-family: 黑体;
              "
            >
              {{ category.name }}
            </div>
          </template>
          <div class="flowers-tab">
            <div
              class="flower-item"
              v-for="flower of flowersList"
              :key="flower.id"
              @click="flowerShow(flower.id)"
            >
              <div class="flower-img">
                <img
                  :src="flower.imageUrls[0]"
                  alt=""
                  width="100%"
                  style="aspect-ratio: 1/1"
                />
              </div>
              <div class="flower-info" style="text-align: start">
                <div class="flower-name text-info">
                  {{ flower.name }}
                </div>
                <div class="flower-description text-info">
                  {{ flower.description }}
                </div>
                <div class="flower-tags text-info">
                  <a-tag
                    v-for="(tag, index) of flower.tags"
                    :key="index"
                    color="var(--my-color)"
                    bordered
                    >{{ tag }}</a-tag
                  >
                </div>
                <div class="flower-price text-info">
                  ￥{{ flower.price }}
                  <add-love-or-cart></add-love-or-cart>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script setup>
import { Message } from "@arco-design/web-vue";
import axios from "axios";
import { ref, onMounted, watch, nextTick } from "vue";
import AddLoveOrCart from "@/components/AddLoveOrCart.vue";
import router from "@/router";

const tabIndex = ref(1);
const categories = ref([]);
const flowersList = ref([]);
const cateId = ref(1);

watch(cateId, (newId) => {
  setActiveTabs(newId);
});

watch(
  flowersList,
  (newList) => {
    newList.forEach((flower) => {
      if (flower.name.length > 9) {
        flower.name = flower.name.slice(0, 9) + "…";
      }
      if (flower.description.length > 10) {
        flower.description = flower.description.slice(0, 10) + "…";
      }
    });
  },
  { deep: true }
);

onMounted(async () => {
  document.querySelector(".arco-tabs-nav-tab-list").style.margin = "auto";
  const res = await axios.get("/category/get-floral-cat");
  if (res.data.code == 0) {
    categories.value = res.data.data;
    await nextTick();
    setActiveTabs(categories.value[0].id);
    getFloralByCateId(categories.value[0].id);
  }
});

const getFloralByCateId = async (id) => {
  cateId.value = id;
  const res = await axios.get(`/floral/get-floral-by-cat/${id}`);
  if (res.data.code == 0) {
    flowersList.value = res.data.data;
  }
};

const flowerShow = (id) => {
  const url = router.resolve({
    path: `/show/${id}`,
  });
  window.open(url.href, "_blank");
};

const tabsChange = async (key) => {
  flowersList.value = [];
  const loading = Message.loading();
  await getFloralByCateId(key);
  loading.close();
};

const setActiveTabs = (id) => {
  const tabElements = document.querySelectorAll(".tab-title");
  tabElements.forEach((element) => {
    element.classList.remove("active-tab");
  });
  document.querySelector(`#id${id}`).classList.add("active-tab");
};
</script>

<style scoped>
.flowers-container {
  width: 100%;
  height: 100%;
  overflow: visible;
}
.flowers-tabs {
}
.active-tab {
  color: var(--my-color) !important;
}
.flowers-tab {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.flower-item {
  width: 90%;
  display: flex;
  border-radius: 1.5vw;
  aspect-ratio: 2.65/1;
  overflow: hidden;
  background-color: var(--color-fill-2);
  margin: auto;
  margin-bottom: 5%;
}

.flower-img {
  width: 80%;
  aspect-ratio: 1/1;
  flex: 0.8;
}
@media screen and (min-width: 601px) {
  .flower-item:hover {
    border: 1px solid var(--my-color);
  }
}
@media screen and (max-width: 600px) {
  .tab-title {
    font-size: 2vh !important;
  }
  .flower-item {
    flex: 0.45;
    width: 95%;
    display: flex;
    border-radius: 1.5vw;
    aspect-ratio: 2.65/1;
    overflow: hidden;
    background-color: var(--color-fill-2);
  }
  .text-info,
  .text-info span {
    font-size: 1vh !important;
  }
  .flowers-tab {
    display: grid;
    grid-template-columns: 90%;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
.flower-info {
  flex: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 5%;
}
.text-info {
}
.text-info,
.text-info span {
  font-size: 1vw;
}
.text-info span {
  height: 2vw !important;
  padding: 0 0.8vw;
}

.flower-name {
  font-weight: 600;
}
.flower-description {
  font-style: italic;
}

.flower-price {
  color: #ff5722;
  font-style: oblique;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>